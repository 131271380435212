import { bodyFontSizes, colors, fastTransition, headerFontSizes, mediaFeatures, medPadding, stdCardBorder, stdPadding, subtextFontSize, titleFontSizes } from "./common";
import { followButton, followButtonActive } from "./modules/buttons";
import { iconButtonLarge, triangleArrow } from "./modules/icon";
import { iconFollow } from "./modules/iconGlyps";
import { pricing, pricingFree } from "./modules/pricing";
import { medTransition } from "./base/transitions";
import { oneAbove, topInGroup } from "./base/zIndex";
import { featuredCardRatio, featuredCardRatioMobile, featuredCardRatioTablet, tinyCardRatio, tinyCardRatioPc } from "./base/ratios";
import { medFontSize, subBannerFontSize } from "./typography";
import { smallPadding } from "./base/padding";
export const card = `
    width: 100%;
	background: white;
    position: relative;
    overflow: visible;
    white-space: normal;
    & > a {
        display: block;
        width: 100%;
        height: 100%;
        text-decoration: none;
        color: inherit;
    }
    & a:hover {
        color: initial;
    }
`;
export const cardImage = `
    display: block;
    position: relative;
    width: 100%;
    overflow: hidden;
    background-color: ${colors["lighter-gray"]};
    figure {
        height: 100%;
    }
    img, video {
        display: block;
        width: 100%;
        height: auto;
        vertical-align: top;
        background: ${colors["lighter-gray"]};
        ${medTransition}
    }
`;
export const cardGenre = `
    ${subtextFontSize}
    position: absolute;
    top: calc(${stdPadding} / 2);
    right: calc(${stdPadding} / 2);
    color: ${colors["white"]};
    text-transform: uppercase;
    background-color: ${colors["insiderRed"]};
    border-radius: 6px;
    font-weight: 700;
    padding: calc(${medPadding} / 2) calc(${stdPadding} / 2);
`;
export const cardIconFollow = `
    position: absolute;
    top: 0;
    left: 0;
    width: 24px;
    height: 27px;
    margin: calc(${stdPadding} / 2) calc((${stdPadding} / 2) - 1px);
    z-index: ${topInGroup};
    ${fastTransition}
    overflow: visible;
    ${iconFollow}
    ${iconButtonLarge}
    span {
        ${subtextFontSize}
        background-color: ${colors["black"]};
        color: ${colors["white"]};
        padding: calc(${medPadding} / 2) calc(${stdPadding} / 2);
        position: absolute;
        opacity: 0;
        font-weight: normal;
        ${fastTransition}
        left: 16px;
        top: 0;
        transition-delay: 0s;
        border-radius: 2px;
    }
	@media screen and ${mediaFeatures["for-pc"]} {
        &:not(.active):hover {
            span {
                transition-delay: 1.25s;
                opacity: 1;
                left: 26px;
            }
        }
    }
`;
export const cardButtonFollow = `
    ${subtextFontSize}
    position: absolute;
    z-index: ${topInGroup};
    bottom: 4vw;
    right: 4vw;
    line-height: 3;
	@media screen and ${mediaFeatures["for-tablet"]} {
        bottom: ${stdPadding};
        right: ${stdPadding};
    }
    @media screen and ${mediaFeatures["for-pc"]} {
        bottom: calc(3.5vw / 2);
        right: calc(3.5vw / 2);
    }
    @media screen and ${mediaFeatures["for-hd"]} {
        bottom: calc(${stdPadding} * 1.5);
        right: calc(${stdPadding} * 1.5);
    }
`;
export const cardButtonFollowNotActive = `
    ${followButton}
    background-color: #3695d8;
    font-weight: normal;
    padding: 0 ${medPadding};
    &::before {
        width: 11px;
        height: 15px;
    }
	@media screen and ${mediaFeatures["for-pc"]} {
        padding: 0 $${stdPadding};
        &::before {
            width: 13px;
            height: 18px;
        }
`;
export const cardButtonFollowActive = `
    ${followButtonActive}
    background-color: ${colors["white"]};
    padding: 0 ${medPadding};
	@media screen and ${mediaFeatures["for-pc"]} {
        padding: 0 ${stdPadding};
    }
`;
export const cardOffer = `
    ${subtextFontSize}
    position: absolute;
    top: calc(${stdPadding} / 2);
    left: calc(${stdPadding} / 2);
    color: ${colors["black"]};
    font-weight: bold;
    text-transform: uppercase;
    background-color: #FBED21;
    border-radius: 2px;
    padding: calc(${medPadding} / 2) calc(${stdPadding} / 2);
`;
export const iconCardImage = `
    width: 43%;
    height: 43%;
    margin: 18% auto 10%;
    img {
        width: 100%;
    }
`;
export const eventMetaInfo = `
    width: 100%;
    display: block;
    font-weight: normal;
    white-space: nowrap;
    overflow: hidden;
    text-overflow: ellipsis;
    line-height: 1.3;
`;
export const cardHover = (cardType = "event") => `
	@media screen and ${mediaFeatures["for-pc"]} {
        .${cardType}-card-action {
            background: rgba(0, 0, 0, 0.5);
        }
        .${cardType}-card-button {
            transform: translate(-50%, -50%) scale(1, 1);
        }
        .type-based-card-footer-background {
            img{
                transform: none;
            }
        }
    }
`;
export const eventCard = `
    ${card}
    border-radius: 6px;
    border: 1px solid ${colors.faintGrey};
    background: white;
    &:hover {
        border: 1px solid white;
        box-shadow: rgba(0, 0, 0, 0.1) 2px 2px 10px 0px;
    }
`;
export const eventCardImage = `
    ${cardImage}
    border-top-left-radius: 6px;
    border-top-right-radius: 6px;
    min-height: 42vw;
    @media screen and (min-width: 560px) {
        min-height: 22vw;
    }
    @media screen and ${mediaFeatures["for-pc"]} {
        min-height: 13vw;
    }
    @media screen and ${mediaFeatures["for-hd"]} {
        min-height: 218px;
    }
`;
export const eventCardAction = `
    position: absolute;
    top: 0;
    bottom: 0;
    width: 100%;
    background: transparent;
    ${medTransition}
	@media screen and ${mediaFeatures["for-mobile"]} {
        transition: none;
    }
`;
export const eventCardDetails = `
    display: flex;
    padding: 9px;
    flex-direction: column;
    gap: 12px;
`;
export const eventCardDetailsTop = `
    width: 100%;
`;
export const eventCardName = `
    width: 100%;
    height: 42px;
    margin-bottom: 9px;
    display: flex;
    align-items: center;
    @media screen and ${mediaFeatures["for-tablet"]} {
        height: 48px;
    }
`;
export const eventCardNameString = `
    color: ${colors.descriptionColor};
    font-size: 14px;
    font-weight: 700;
    line-height: 150%;
    display: -webkit-box;
    -webkit-line-clamp: 2;
    -webkit-box-orient: vertical;
    overflow: hidden;
    @media screen and ${mediaFeatures["for-tablet"]} {
        font-size: 16px;
    }
`;
export const featuredCardNameString = `
    ${eventCardNameString}
    color: white;
    font-size: 16px;
`;
export const eventCardContainer = `
    width: 100%;
    display: flex;
    justify-content: space-between;
`;
export const eventCardPrice = `
    width: 80%;
    ${eventMetaInfo}
    font-size: 3.2vw;
    ${pricing}
    font-weight: normal;
	@media screen and ${mediaFeatures["for-tablet"]} {
        font-size: 1.9vw;
    }
	@media screen and ${mediaFeatures["for-large-tablet"]} {
        font-size: 1.5vw;
    }
	@media screen and ${mediaFeatures["for-pc"]} {
        font-size: 1.1vw;
    }
	@media screen and ${mediaFeatures["for-large-pc"]} {
        font-size: 1vw;
    }
	@media screen and ${mediaFeatures["for-hd"]} {
        font-size: ${medFontSize}
    }
`;
export const eventCardPriceFree = `
    ${pricingFree}
`;
export const eventCardDateVenueContainer = `
    display: flex;
    gap: 9px;
    align-items: center;
    margin-top: 9px;
`;
export const eventDateVenue = `
    color: ${colors.lightGray};
    font-size: 12px;
    font-weight: 600;
    line-height: 150%;
    white-space: nowrap;
    overflow: hidden;
    text-overflow: ellipsis;

    @media screen and ${mediaFeatures["for-tablet"]} {
        font-size: 13px;
    }
`;
export const featuredCardDateVenueContainer = `
    ${eventCardDateVenueContainer}
    gap: 6px;
`;
export const featuredDateVenue = `
    ${eventDateVenue}
`;
export const eventCardDateVenue = `
    ${eventMetaInfo}
    color: #676465;
    font-size: 3.2vw;
	@media screen and ${mediaFeatures["for-tablet"]} {
        font-size: 1.9vw;
    }
    @media screen and ${mediaFeatures["for-large-tablet"]} {
        font-size: 1.5vw;
    }
    @media screen and ${mediaFeatures["for-pc"]} {
        font-size: 1.1vw;
    }
    @media screen and ${mediaFeatures["for-large-pc"]} {
        font-size: 1vw;
    }
    @media screen and ${mediaFeatures["for-hd"]} {
        font-size: ${medFontSize}
    }
`;
export const eventCardDate = `
    ${eventCardDateVenue}
    margin-bottom: 1.6vw;
	@media screen and ${mediaFeatures["for-tablet"]} {
        margin-bottom: 0.65vw;
    }
    @media screen and ${mediaFeatures["for-large-tablet"]} {
        margin-bottom: 0.9vw;
    }
    @media screen and ${mediaFeatures["for-pc"]} {
        margin-bottom: 0.5vw;
    }
    @media screen and ${mediaFeatures["for-large-pc"]} {
        margin-bottom: 0.4vw;
    }
    @media screen and ${mediaFeatures["for-hd"]} {
        margin-bottom: calc(${stdPadding} / 2);
    }
`;
export const eventCardVenue = `
    ${eventCardDateVenue}
    margin-bottom: 1.8vw;
	@media screen and ${mediaFeatures["for-tablet"]} {
        margin-bottom: 0.7vw;
    }
    @media screen and ${mediaFeatures["for-large-tablet"]} {
        margin-bottom: 1.05vw;
    }
    @media screen and ${mediaFeatures["for-pc"]} {
        margin-bottom: 0.55vw;
    }
    @media screen and ${mediaFeatures["for-large-pc"]} {
        margin-bottom: 0.4vw;
    }
    @media screen and ${mediaFeatures["for-hd"]} {
        margin-bottom: 10px;
    }
`;
export const eventCardRibbon = `
    ${cardGenre}
    background-color: ${colors["light-gray"]};
    right: auto;
    left: -10px;
    border-radius: 0 2px 2px 0;
    font-weight: 400;
    &::before {
        content: "";
        position: absolute;
        z-index: -1;
        height: ${stdPadding};
        width: ${stdPadding};
        background-color: ${colors["gray"]};
        left: 0;
        bottom: 0;
        transform: rotate(35deg);
        transform-origin: 0 100%;
    }
`;
export const cellCard = `
    ${card}
`;
export const cellCardImage = `
    ${cardImage}
    height: 100%;
`;
export const cellCardName = `
    font-size: 1.25rem;
	@media screen and ${mediaFeatures["for-mobile"]} {
        font-size: 16px;
    }
    line-height: 1.2;
    padding: 51% ${stdPadding} ${medPadding} ${stdPadding};
    position: absolute;
    display: block;
    z-index: ${oneAbove};
    width: 100%;
    bottom: 0;
    left: 0;
    background: linear-gradient(to top, black, rgba(0, 0, 0, 0.3), rgba(0, 0, 0, 0));
    color: ${colors["white"]};
    font-weight: bold;
`;
export const cellCardNameRemoveBg = `
	@media screen and ${mediaFeatures["for-mobile"]} {
        background: unset;
    }
`;
export const newyearCard = `
    ${cellCard}
`;
export const newyearCardImage = `
    ${cellCardImage}
    border-radius: 2px;
`;
export const articleCard = `
    ${cellCard}
    border-radius: 6px;
    overflow: hidden;
    transform: translateZ(0);
    & > a:hover {
        color: initial;
	    @media screen and ${mediaFeatures["for-pc"]} {
            img, video {
                transform: none;
            }
        }
    }
`;
export const articleCardImage = `
    ${cellCardImage}
`;
export const articleCardName = `
    ${cellCardName}
    border-bottom-left-radius: 6px;
    border-bottom-right-radius: 6px;
`;
export const smallCard = `
    ${articleCard}
`;
export const smallCardImage = `
    ${cellCardImage}
    @media screen and ${mediaFeatures["for-mobile"]} {
        width: 40%;
        display: inline-block;
        vertical-align: top;
        border-radius: 2px;
    }
`;
export const smallCardInfo = `
    @media screen and ${mediaFeatures["for-mobile"]} {
        width: 60%;
        padding: 0 ${stdPadding};
        display: inline-block;
        vertical-align: top;
        overflow: hidden;
    }
`;
export const smallCardGenre = `
    @media screen and ${mediaFeatures["for-mobile"]} {
        position: static;
        color: ${colors["insiderRed"]};
        padding: 0;
        background-color: transparent !important;
        font-size: 85%;
    }
`;
export const smallCardName = `
    ${cellCardName}
    @media screen and ${mediaFeatures["for-mobile"]} {
        position: static;
        background-color: transparent;
        color: ${colors["black"]};
        font-weight: normal;
        padding: 0;
        margin-top: calc(${medPadding} / 3);
        line-height: 1.42;
    }
`;
export const featuredCard = `
    ${card}
    border-radius: 12px;
    border: 1px solid ${colors.faintGrey};
    overflow: hidden;
    transform: translateZ(0);
    &:hover {
        border: 1px solid white;
        box-shadow: rgba(0, 0, 0, 0.1) 2px 2px 10px 0px;
    }
`;
export const featuredCardImage = `
    ${cardImage}
    height: calc(100% * ${featuredCardRatioMobile} / ${featuredCardRatio});
    @media screen and ${mediaFeatures["for-small-tablet-only"]} {
        height: calc(100% * ${featuredCardRatioTablet} / ${featuredCardRatio});
    }
`;
export const featuredCardAction = `
    ${eventCardAction}
`;
export const featuredCardName = `
    color: white;
    font-size: 16px;
    font-weight: 700;
    line-height: 150%;
    padding: 9px;
    position: absolute;
    display: block;
    z-index: ${oneAbove};
    width: 100%;
    bottom: 0;
    left: 0;
    font-weight: bold;
    background: linear-gradient(180deg, rgba(0, 0, 0, 0.00) 0%, #000 100%);
`;
export const featuredCardDetails = `
    ${eventCardDetails}
    gap: 9px;
`;
export const featuredCardDate = `
    ${eventCardDate}
    width: 100%;
    @media screen and ${mediaFeatures["for-mobile"]} {
        margin-top: 1vw;
    }
`;
export const featuredCardVenue = `
    ${eventCardVenue}
`;
export const featuredCardPrice = `
    ${eventCardPrice}
    margin-bottom: 1vw;
`;
export const tinyCard = `
    ${card}
    box-shadow: 2px 4px 18px 0 rgba(0, 0, 0, 0.1);
    border-radius: 6px;
    overflow: hidden;
    transform: translateZ(0);
    a:hover {
        ${cardHover("featured")}
    }
    box-shadow: none;
    border-radius: 6px;
    overflow: hidden;
    & > a:hover {
        color: initial;
        @media screen and ${mediaFeatures["for-pc"]} {
            img, video {
                transform: none;
            }
        }
    }
`;
export const tinyCardImage = `
    ${cardImage}
    background-color: #fff;
    height: calc(100% * ${tinyCardRatio});
    @media screen and ${mediaFeatures["for-pc"]} {
        height: calc(100% * ${tinyCardRatioPc});
        overflow: inherit;
    }
`;
export const tinyCardImageArtist = `
    img {
        border-radius: 50%;
        height: auto;
    }
`;
export const tinyCardImageVenue = `
    img {
        border-radius: 18px;
    }
`;
export const tinyCardDetails = `
    height: calc(100% - (100% * ${tinyCardRatio}));
    padding-top: calc(${medPadding} / 2);
    overflow: hidden;
    text-align: center;
    @media screen and ${mediaFeatures["for-mobile"]} {
        height: 30%;
    }
    @media screen and ${mediaFeatures["for-large-pc"]} {
        padding-top: 10px;
    }
`;
export const tinyCardName = `
    ${eventCardDate}
    color: #000000;
    @media screen and ${mediaFeatures["for-mobile"]} {
        font-size: 3.2vw;
    }
    font-weight: 500;
    line-height: 1.2;
`;
export const tinyCardSubtext = `
    ${eventCardVenue}
    color: black;
    opacity: 0.6;
    line-height: 1;
    @media screen and ${mediaFeatures["for-mobile"]} {
        font-size: 3vw;
    }
    @media screen and ${mediaFeatures["for-pc"]} {
        margin-top: -1px;
    }
`;
export const timefilterCard = `
    ${card}
    height: unset;
    padding: ${medPadding};
    border-radius: 6px;
    overflow: hidden;
    transform: translateZ(0);
    border: solid 1px #e3e5e7;
    display: flex;
    flex-flow: column nowrap;
    align-items: flex-start;
    justify-content: space-around;
    &:hover {
        border-color: ${colors["insiderPink"]};
    }
    @media screen and ${mediaFeatures["for-pc"]} {
        padding: ${medPadding};
    }
    @media screen and ${mediaFeatures["for-large-pc"]} {
        padding: ${stdPadding};
    }
`;
export const timefilterCardImage = `
    width: 23%;
    margin-left: auto;
    position: relative;
    img {
        width: 100%;
        vertical-align: bottom;
    }
    @media screen and ${mediaFeatures["for-pc"]} {
        width: 25%;
    }
    @media screen and ${mediaFeatures["for-large-pc"]} {
        width: 22%;
    }
`;
export const timefilterCardName = `
    font-weight: normal;
    font-size: 24px;
    font-weight: bold;
    color: ${colors["insiderPink"]};
    @media screen and ${mediaFeatures["for-mobile"]} {
        font-size: 3.75vw;
    }
    @media screen and ${mediaFeatures["for-small-tablet-only"]} {
        font-size: 3.5vw;
    }
    @media screen and ${mediaFeatures["for-large-tablet-only"]} {
        font-size: 3vw;
    }
    @media screen and ${mediaFeatures["for-large-tablet-only"]} {
        font-size: 3vw;
    }
`;
export const timefilterCardDateString = `
    margin-top: 2px;
    font-size: 14px;
    color: #666666;
    @media screen and ${mediaFeatures["for-mobile"]} {
        font-size: 3.2vw;
    }
    @media screen and ${mediaFeatures["for-small-tablet-only"]} {
        font-size: 2.5vw;
    }
    @media screen and ${mediaFeatures["for-large-tablet-only"]} {
        font-size: 2vw;
    }
`;
export const timefilterCardDate = `
    position: absolute;
    right: calc(${stdPadding} + 8.5%);
    top: calc((${medPadding} * 1.5) + 33%);
    transform: translate(50%, -50%);
    color: ${colors["insiderRed"]};
    font-weight: bold;
    ${subBannerFontSize}
    @media screen and ${mediaFeatures["for-large-pc"]} {
        top: calc(${medPadding} * 1.5 + 35%);
        right: calc(${stdPadding} + 9.5%);
    }
`;
export const timefilterCardDateWeekend = `
    ${bodyFontSizes}
    font-size: 90%;
    @media screen and ${mediaFeatures["for-large-pc"]} {
        font-size: 90%;
    }
`;
export const highlightCard = `
    ${card}
    ${smallPadding()}
    border: ${stdCardBorder};
    overflow: auto;
`;
export const highlightCardName = `
    display: block;
    font-weight: normal;
    ${headerFontSizes}
    margin-bottom: calc(${stdPadding} /2);
    @media screen and ${mediaFeatures["for-pc"]} {
        margin-bottom: ${stdPadding};
    }
`;
export const highlightCardDetails = `
    width: 100%;
    line-height: 1.6;
    font-weight: normal;
    p {
        float: left;
        width: 80%;
    }
`;
export const highlightCardImage = `
    float: right;
    width: 12%;
    padding-top: calc(${stdPadding} /2);
    img {
        width: 100%;
        vertical-align: top;
    }
`;
export const partnerCard = `
    ${card}
    border: ${stdCardBorder};
    overflow: hidden;
    &:hover {
        @media screen and ${mediaFeatures["for-pc"]} {
            img {
                transform: scale(1.2);
            }
        }
    }
`;
export const partnerCardImage = `
    ${cardImage}
    ${triangleArrow}
    width: 50%;
    height: 100%;
    float: left;
    &::after {
        z-index: ${topInGroup};
        top: 16px;
        right: 0;
        border-right-color: ${colors["white"]};
    }
`;
export const partnerCardAction = `
    ${eventCardAction}
`;
export const partnerCardDetails = `
    ${smallPadding()}
    width: 50%;
    height: 100%;
    float: left;
`;
export const partnerCardDetailsContainer = `
    overflow: hidden;
    position: relative;
    height: 100%;
`;
export const partnerCardName = `
    ${headerFontSizes}
    margin-bottom: calc(${medPadding} /2);
    font-weight: bold;
    @media screen and ${mediaFeatures["for-mobile"]} {
        font-size: 120%;
    }
    @media screen and ${mediaFeatures["for-pc"]} {
        margin-bottom: calc(${stdPadding} / 2);
    }
    @media screen and ${mediaFeatures["for-large-pc"]} {
        margin-bottom: ${medPadding};
    }
`;
export const partnerCardDesc = `
    ${bodyFontSizes}
    line-height: 1.45;
    font-weight: normal;
    @media screen and ${mediaFeatures["for-pc"]} {
        line-height: 1.6;
    }
`;
export const partnerCardDescShort = `
    display: block;
    @media screen and ${mediaFeatures["for-small-tablet-only"]} {
        display: none;
    }
    @media screen and ${mediaFeatures["for-pc"]} {
        display: none;
    }
`;
export const partnerCardDescLong = `
    display: none;
    @media screen and ${mediaFeatures["for-pc"]} {
        display: block;
    }
`;
export const partnerCardSubtext = `
    position: absolute;
    background-color: ${colors["black"]};
    border-radius: 2px;
    color: ${colors["white"]};
    bottom: calc(${stdPadding}/2);
    left: calc(${stdPadding}/2);
    padding: calc(${medPadding}/2) calc(${stdPadding}/2);
    line-height: 1.2;
    &:empty {
        display: none;
    }
    @media screen and ${mediaFeatures["for-small-tablet-only"]} {
        position: static;
    }
`;
export const searchCard = `
    ${card}
    & > a:hover {
        ${cardHover("search")};
    }
`;
export const searchCardImage = `
    ${cardImage}
    width: 40%;
    display: inline-block;
    vertical-align: top;
    border-radius: 6px;
    overflow: hidden;
    transform: translateZ(0);
    &::after {
        content: '';
        position: absolute;
        top: 0;
        right: 0;
        bottom: 0;
        left: 0;
        border: 1px solid rgba(0, 0, 0, 0.1);
        border-radius: 6px;
    }
`;
export const searchCardAction = `
    ${eventCardAction}
`;
export const searchCardDetails = `
    width: 60%;
    overflow: hidden;
    display: inline-block;
    vertical-align: top;
    ${smallPadding("left")}
    @media screen and ${mediaFeatures["for-small-tablet-only"]} {
        height: 124%;
    }
    @media screen and ${mediaFeatures["for-pc"]} {
        height: 110%;
    }
`;
export const searchCardName = `
    ${titleFontSizes}
    margin-bottom: calc(${medPadding} / 3);
    font-weight: bold;
    line-height: 1.35;
`;
export const searchCardDatePrice = `
    ${eventMetaInfo}
    ${bodyFontSizes}
    font-size: 95%;
`;
export const searchCardDate = `
    ${searchCardDatePrice}
    margin-bottom: calc(${medPadding} / 2);
`;
export const searchCardVenue = `
    display: none;
`;
export const searchCardPrice = `
    ${pricing}
    font-weight: normal;
`;
export const searchCardPriceFree = `
    ${pricingFree}
`;
export const categoryCard = `
    @media screen and ${mediaFeatures["for-pc"]} {
        padding: ${medPadding} ${stdPadding};
    }
`;
export const eventCardNoData = `
    border: none;
`;
/* no data counterparts */
export const noDataWrapper = `
    height: 100%;
`;
export const eventCardImageNoData = `
    ${featuredCardImage}
    height: 42%;
`;
export const eventCardDetailsNoData = `
    padding-left: 0;
    padding-right: 0;
    ${featuredCardDetails}
    height: 42%;
    @media screen and ${mediaFeatures["for-pc"]} {
        height: 10px;
    }
    @media screen and ${mediaFeatures["for-large-pc"]} {
        height: 12px;
    }
`;
export const eventCardNameNoData = `
    display: block;
    padding: 2vw 0 !important;
    margin-bottom: 0;
    @media screen and ${mediaFeatures["for-mobile"]} {
        padding: 8vw 0 !important;
    }
`;
export const eventCardNameStringNoData = `
    background: ${colors["lighter-gray"]};
    display: block;
    width: 70%;
    height: 11px;
    border-radius: 20px;
    @media screen and ${mediaFeatures["for-pc"]} {
        height: 12px;
    }
    @media screen and ${mediaFeatures["for-large-pc"]} {
        height: 14px;
    }
	animation: data-loading 0.75s ease-in-out infinite;
`;
export const eventCardDateNoData = `
    background: #eee;
    display: block;
    height: 9px;
    border-radius: 20px;
    width: 50%;
    margin-bottom: 1.25vw !important;
	animation: data-loading 0.75s ease-in-out infinite;
    @media screen and ${mediaFeatures["for-mobile"]} {
        margin-bottom: 4.25vw !important;
    }
`;
export const eventCardVenueNoData = `
    background: #eee;
    display: block;
    height: 9px;
    border-radius: 20px;
    width: 60%;
	animation: data-loading 0.75s ease-in-out infinite;
`;
export const eventCardPriceNoData = `
    width: 40%;
    &::before {
        content: none;
    }
`;
export const featuredCardNoData = `
    border: none;
`;
export const featuredCardImageNoData = `
    ${featuredCardImage}
`;
export const featuredCardDetailsNoData = `
    padding-left: 0;
    padding-right: 0;
`;
export const featuredCardNameNoData = `
    display: none;
`;
export const tinyCardNoData = `
    border: none;
`;
export const tinyCardDetailsNoData = `
    padding-left: 0;
    padding-right: 0;
`;
export const tinyCardImageNoData = `
    ${tinyCardImage}
`;
export const tinyCardNameNoData = `
    display: none;
`;
export const tinyCardPriceNoData = `
    display: none;
`;
export const partnerCardNoData = `
	background: linear-gradient(to right, ${colors["slightly-lighter-gray"]} 0%, ${colors["lighter-gray"]} 30%);
	animation: data-loading 0.75s ease-in-out infinite;
    img {
		display: none;
	}
`;
export const partnerCardActionNoData = `
    display: none;
`;
export const partnerCardNameNoData = `
    ${partnerCardNoData}
    width: 80%;
    height: 11px;
    border-radius: 20px;
    margin-bottom: calc(${stdPadding} / 2);
    @media screen and ${mediaFeatures["for-pc"]} {
        margin-bottom: ${stdPadding};
    }
`;
export const partnerCardDescNoData = `
    ${partnerCardNoData}
    width: 70%;
    height: 10px;
    border-radius: 20px;
    margin-bottom: ${medPadding};
    margin-bottom: calc(${medPadding} / 2);
`;
/* large event card selective styles */
export const largeEventCardVisual = `
    ${eventCardImage}
    img {
        width: 100%;
        vertical-align: bottom;
    }
`;
export const largeEventCardNameString = `
    ${eventCardNameString}
    font-weight: bold;
    @media screen and ${mediaFeatures["for-pc"]} {
        font-size: 130%;
    }
    @media screen and ${mediaFeatures["for-large-pc"]} {
        line-height: 1.75vw;
    }
`;
export const largeEventCardVenuePricing = `
    width: 60%;
`;
export const eventFooterContainer = (isPhysical) => `
    display: flex;
    padding: 9px;
    align-items: center;
    gap: 3px;
    border-radius: 9px;
    background: ${isPhysical ? colors.backgroundBlue : colors.backgroundPink};
    width: 100%;
    justify-content: space-between;
`;
export const eventFooterText = `
    color: ${colors.primaryDark};
    font-family: 'korolev-condensed';
    font-size: 16px;
    font-style: normal;
    font-weight: 700;
    line-height: 100%;
    text-transform: uppercase;
    height: 38px;
`;
export const eventFooterBuyNow = (isPhysical) => `
    border-left: 1px solid ${isPhysical ? colors.primaryBlue : colors.primaryPink};
    padding-left: 9px;
    min-width: 64px;
`;
export const featuredFooterContainer = (isPhysical) => `
    ${eventFooterContainer(isPhysical)}
`;
export const featuredFooterText = `
    ${eventFooterText}
`;
export const featuredFooterBuyNow = (isPhysical) => `
    ${eventFooterBuyNow(isPhysical)}
`;
export const eventFooterPrice = `
    text-overflow: ellipsis;
    overflow: hidden;
    white-space: nowrap;
`;
export const featuredFooterPrice = `
    ${eventFooterPrice}
`;

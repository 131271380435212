import React from "react";
import { InsiderIconWithGreyBg } from "../../components/ui/icons/InsiderIconWithGreyBg";
import { Music } from "../../components/ui/icons/CategoryIcons/Music";
/**
 * @type {Link}
 * @property {string} resource_type - The type of resource, possible values are: "tag", "event", "article", "category", "external", "group", "go" and "filtered_list"
 * @property {string} [title] - The title of the link.
 * @property {string} [slug] - The slug for the link.
 * @property {string} [url] - The URL of the link (applicable for "external" resource_type).
 * @property {Object} [query_params] - Additional query parameters for the link (applicable for "filtered_list" resource_type).
 */
/**
 * @type {IIconCard}
 * @property {string} title - The title of the quick link.
 * @property {string} subtitle - The subtitle associated with the quick link.
 * @property {string} image - The URL of the image associated with the quick link.
 * @property {string[]} [includeCities] - An array of cities to include for the quick link. Add ["global"] for adding it in all cities. includeCities takes priority over excludeCities
 * @property {string[]} [excludeCities] - An array of cities to exclude for the quick link.
 * @property {Link} link - The object containing params to construct the links
 * @property {boolean} openInNewTab - Boolean set to true to open links in new tab
 */
/**
 * Configuration for the homepage UI components.
 * @type {IIconCard[]}
 */
// this is a temporary config to power the homepage ui components untill the admin flow is built
// note: if more than 3 quicklinks qualify for a city, they will be shuffled
export const quickLinks = [
    //Mumbai
    {
        title: "Innellea in India",
        subtitle: "THE BELONGING Tour",
        image: "https://media.insider.in/image/upload/c_crop,g_custom/v1729863557/q04twowyj26akop4y7bu.jpg",
        includeCities: ["Mumbai"],
        link: {
            resource_type: "event",
            slug: "innellea-mumbai-nov16-2024"
        }
    },
    {
        title: "World Pickleball Championship ",
        subtitle: "RSVP Now!",
        image: " https://media.insider.in/image/upload/c_crop,g_custom/v1731486603/b0kgq3uj94f74ebtq6u8.jpg",
        includeCities: ["Mumbai"],
        link: {
            resource_type: "event",
            slug: "world-pickleball-championship-2024"
        }
    },
    {
        title: "Russell Howard LIVE",
        subtitle: "Catch him LIVE in Feb!",
        image: "https://media.insider.in/image/upload/c_crop,g_custom/v1731137049/vlsnycyfwk9jb7zrvnql.jpg",
        includeCities: ["Mumbai"],
        link: {
            resource_type: "event",
            slug: "russell-howard-live-feb8-2025"
        }
    },
    //Delhi
    {
        title: "DEL Comic Con",
        subtitle: "Pop Culture Showcase",
        image: "https://media.insider.in/image/upload/c_crop,g_custom/v1728702618/s9igtvwc2s25xsc9rsx6.jpg",
        includeCities: ["Delhi"],
        link: {
            resource_type: "event",
            slug: "delhi-comic-con-2024-dec6-2024"
        }
    },
    {
        title: "Legacy Rewind",
        subtitle: "Farhan Akhtar, LIVE!",
        image: "https://media.insider.in/image/upload/c_crop,g_custom/v1730441543/b9zkvi83mvtgrneaigbn.jpg",
        includeCities: ["Delhi"],
        link: {
            resource_type: "event",
            slug: "legacy-rewind-ft-farhan-akhtar-live-noida-nov16-2024"
        }
    },
    {
        title: "Echoes Of Earth",
        subtitle: "Coming to Delhi Soon!",
        image: "https://media.insider.in/image/upload/c_crop,g_custom/v1731137949/oymzvt3iuy1zzdv1glpq.jpg",
        includeCities: ["Delhi"],
        link: {
            resource_type: "event",
            slug: "echoes-of-earth-ft-vieux-farka-tour-delhi-nov15-2024"
        }
    },
    //Bengaluru
    {
        title: "Russell Howard LIVE",
        subtitle: "Catch him LIVE in Feb!",
        image: "https://media.insider.in/image/upload/c_crop,g_custom/v1731137049/vlsnycyfwk9jb7zrvnql.jpg",
        includeCities: ["Bengaluru"],
        link: {
            resource_type: "event",
            slug: "russell-howard-live-bengaluru-feb9-2025"
        }
    },
    {
        title: "BLR Comic Con",
        subtitle: "Pop Culture Showcase",
        image: "https://media.insider.in/image/upload/c_crop,g_custom/v1728703930/wywvfqsph7kvvjgbf717.jpg",
        includeCities: ["Bengaluru"],
        link: {
            resource_type: "event",
            slug: "bengaluru-comic-con-jan18-2025"
        }
    },
    {
        title: "Nature is calling",
        subtitle: "Echoes of Earth 2024",
        image: "https://media.insider.in/image/upload/c_crop,g_custom/v1729863961/pil01mck4ptz2pkv9fds.jpg",
        includeCities: ["Bengaluru"],
        link: {
            resource_type: "event",
            slug: "echoesofearth2024bangalore"
        }
    },
    //Pune
    {
        title: "A Roaring Time",
        subtitle: "At Dinoverse!",
        image: "https://media.insider.in/image/upload/c_crop,g_custom/v1731137164/ki0tgqmnufs9kur66id3.jpg",
        includeCities: ["Pune"],
        link: {
            resource_type: "event",
            slug: "dinoverse-an-epic-adventure-at-nexus-westen-mall-oct3-2024"
        }
    },
    {
        title: "Good Luck Beta",
        subtitle: "PCRC on Tour",
        image: "https://media.insider.in/image/upload/c_crop,g_custom/v1729863756/hi2bfuyth28rtyo5wqyb.jpg",
        includeCities: ["Pune"],
        link: {
            resource_type: "event",
            slug: "peter-cat-recording-co-good-luck-beta-24-pune-dec14-2024"
        }
    },
    {
        title: "NH7 Weekender",
        subtitle: "Back to Pune!",
        image: "https://media.insider.in/image/upload/c_crop,g_custom/v1731137974/ho7drchmjnak4dunbci3.jpg",
        includeCities: ["Pune"],
        link: {
            resource_type: "event",
            slug: "nh7-weekender-2024-pune-dec14-2024"
        }
    },
    //Goa
    {
        title: "No EDM, No Party",
        subtitle: "Raeeth, Goa",
        image: "https://media.insider.in/image/upload/c_crop,g_custom/v1727699563/qx8djyzerxqedakh6nxc.jpg",
        includeCities: ["Goa"],
        link: {
            resource_type: "event",
            slug: "raeeth-presents-experience-31-dec-dec31-2024"
        }
    },
    {
        title: "Ben Bohmer LIVE",
        subtitle: "NYE House Scenes",
        image: "https://media.insider.in/image/upload/c_crop,g_custom/v1731137307/zs3gmsjstl4paaaerdyv.jpg",
        includeCities: ["Goa"],
        link: {
            resource_type: "event",
            slug: "nye-w-ben-bohmer-bloom-live-circus-namascray"
        }
    },
    {
        title: "Korolova LIVE",
        subtitle: "In Goa This Dec!",
        image: "https://media.insider.in/image/upload/c_crop,g_custom/v1731138025/updmsusmib72ebor85pu.jpg",
        includeCities: ["Goa"],
        link: {
            resource_type: "event",
            slug: "korolova-at-salud-goa-dec13-2024"
        }
    },
    //Hyderabad
    {
        title: "La La Land",
        subtitle: "Flea In Hyderabad!",
        image: "https://media.insider.in/image/upload/c_crop,g_custom/v1731137197/b8zfbbbqd03gufgpyfb7.jpg",
        includeCities: ["Hyderabad"],
        link: {
            resource_type: "event",
            slug: "la-la-land-season-5-a-experiential-flea-dec1-2024"
        }
    },
    {
        title: "Mixtape Volume II",
        subtitle: "On November 24th",
        image: "https://media.insider.in/image/upload/c_crop,g_custom/v1731142142/lf3e7q3iijgeo4t3od5m.jpg",
        includeCities: ["Hyderabad"],
        link: {
            resource_type: "event",
            slug: "mixtape-volume-ii-locker-room-nov24-2024"
        }
    },
    {
        title: "Open the Vault",
        subtitle: "DJ Oliver Huntemann",
        image: "https://media.insider.in/image/upload/c_crop,g_custom/v1729863883/pgzlew3nju0mm8h3xuom.jpg",
        includeCities: ["Hyderabad"],
        link: {
            resource_type: "event",
            slug: "saturday-soundscapes-ft-oliver-huntemann-nov16-2024"
        }
    },
    //Chennai
    {
        title: "Sonu Nigam, LIVE",
        subtitle: "On November 30th",
        image: "https://media.insider.in/image/upload/c_crop,g_custom/v1728704460/cbuvqikiflbknngqccyx.jpg",
        includeCities: ["Chennai"],
        link: {
            resource_type: "event",
            slug: "sonu-nigam-live-in-chennai-nov30-2024"
        }
    },
    {
        title: "Jazz it up",
        subtitle: "Madras Jazz Festival",
        image: "https://media.insider.in/image/upload/c_crop,g_custom/v1729863793/sgxwrnnccbn1nxw3gqpu.jpg",
        includeCities: ["Chennai"],
        link: {
            resource_type: "event",
            slug: "the-madras-jazz-festival-dec8-2024"
        }
    },
    {
        title: "Indie Music Fest",
        subtitle: "From Nov 22nd to 24th",
        image: "https://media.insider.in/image/upload/c_crop,g_custom/v1728704243/rsnlabh3gfzcxmz0pgau.jpg",
        includeCities: ["Chennai"],
        link: {
            resource_type: "event",
            slug: "international-independent-music-festival-iimf-2024-nov22-2024"
        }
    },
    //Kolkata
    {
        title: "The Soul Local",
        subtitle: "With 6 LIVE artists!",
        image: "https://media.insider.in/image/upload/c_crop,g_custom/v1731142120/govjzfl9s1tosbbn5rel.jpg",
        includeCities: ["Kolkata"],
        link: {
            resource_type: "event",
            slug: "the-soul-local-dec21-2024"
        }
    },
    {
        title: "Agents of Time",
        subtitle: "LIVE on Dec 29th!",
        image: "https://media.insider.in/image/upload/c_crop,g_custom/v1729498345/hbubi7mz5x3x0mzhwf2o.jpg",
        includeCities: ["Kolkata"],
        link: {
            resource_type: "event",
            slug: "reverb-rti-present-agents-of-time-kolkata"
        }
    },
    {
        title: "It's a 'Mehfil'",
        subtitle: "Bismil Ke Mehfil ",
        image: "https://media.insider.in/image/upload/c_crop,g_custom/v1728704307/iik0biqehnzcn18smnj7.jpg",
        includeCities: ["Kolkata"],
        link: {
            resource_type: "event",
            slug: "bismil-ke-mehfil-dec28-2024"
        }
    },
    //coimbatore
    {
        title: "Chennai Comic Con",
        subtitle: "Pop Culture Showcase",
        image: "https://media.insider.in/image/upload/c_crop,g_custom/v1730441571/okp06lddyswwrw2qyg3e.jpg",
        includeCities: ["Coimbatore"],
        link: {
            resource_type: "event",
            slug: "chennai-comic-con-2024-feb8-2025"
        }
    },
    {
        title: "Sonu Nigam, LIVE",
        subtitle: "On November 30th",
        image: "https://media.insider.in/image/upload/c_crop,g_custom/v1728704460/cbuvqikiflbknngqccyx.jpg",
        includeCities: ["Coimbatore"],
        link: {
            resource_type: "event",
            slug: "sonu-nigam-live-in-chennai-nov30-2024"
        }
    },
    {
        title: "Indie Music Fest",
        subtitle: "17 bands, Camping & more!",
        image: "https://media.insider.in/image/upload/c_crop,g_custom/v1729863826/gxgvj8icvuwnikn65ami.jpg",
        includeCities: ["Coimbatore"],
        link: {
            resource_type: "event",
            slug: "international-independent-music-festival-iimf-2024-nov22-2024"
        }
    },
    //visakhapatnam
    {
        title: "Shop till you drop",
        subtitle: "Sunday Soul Sante",
        image: "https://media.insider.in/image/upload/c_crop,g_custom/v1729498426/pt0unvt7q3hkmw9nnrbv.jpg",
        includeCities: ["Visakhapatnam"],
        link: {
            resource_type: "event",
            slug: "sunday-soul-sante-hyderabad-17th-edition-nov10-2024"
        }
    },
    {
        title: "Chale Aana",
        subtitle: "See Armaan Malik, LIVE!",
        image: "https://media.insider.in/image/upload/c_crop,g_custom/v1724039123/dmb784vsdxfcypyyvi5i.jpg",
        includeCities: ["Visakhapatnam"],
        link: {
            resource_type: "event",
            slug: "armaan-malik-live-in-vizag-nov9-2024"
        }
    },
    {
        title: "Dreamhack x CominCon",
        subtitle: "Best of both worlds",
        image: "https://media.insider.in/image/upload/c_crop,g_custom/v1726905193/zbpkf3s5t8a2yiwunfmt.jpg",
        includeCities: ["Visakhapatnam"],
        link: {
            resource_type: "event",
            slug: "dreamhack-hyderabad-comic-con-2024-nov15-2024"
        }
    },
    //vizag
    {
        title: "Shop till you drop",
        subtitle: "Sunday Soul Sante",
        image: "https://media.insider.in/image/upload/c_crop,g_custom/v1729498426/pt0unvt7q3hkmw9nnrbv.jpg",
        includeCities: ["Vizag"],
        link: {
            resource_type: "event",
            slug: "sunday-soul-sante-hyderabad-17th-edition-nov10-2024"
        }
    },
    {
        title: "Chale Aana",
        subtitle: "See Armaan Malik, LIVE!",
        image: "https://media.insider.in/image/upload/c_crop,g_custom/v1724039123/dmb784vsdxfcypyyvi5i.jpg",
        includeCities: ["Vizag"],
        link: {
            resource_type: "event",
            slug: "armaan-malik-live-in-vizag-nov9-2024"
        }
    },
    {
        title: "Dreamhack x CominCon",
        subtitle: "Best of both worlds",
        image: "https://media.insider.in/image/upload/c_crop,g_custom/v1726905193/zbpkf3s5t8a2yiwunfmt.jpg",
        includeCities: ["Vizag"],
        link: {
            resource_type: "event",
            slug: "dreamhack-hyderabad-comic-con-2024-nov15-2024"
        }
    }
];
export const placeholderQuickLinks = [
    {
        title: "Host an Event",
        subtitle: "Make your event live in under 5 mins",
        image: <InsiderIconWithGreyBg />,
        includeCities: ["global"],
        link: {
            resource_type: "external",
            url: "/list-your-events"
        }
    },
    {
        title: "Events this weekend",
        subtitle: "Events this weekend",
        image: <InsiderIconWithGreyBg />,
        includeCities: ["global"],
        link: {
            resource_type: "everything",
            time_filter: "weekend"
        }
    }
];
export const mobilePlaceHolderLinks = [
    {
        title: "Download our app now!",
        subtitle: "Android & iOS",
        image: <InsiderIconWithGreyBg />,
        includeCities: ["global"],
        link: {
            resource_type: "external",
            url: "https://link.insider.in/app"
        }
    },
    ...placeholderQuickLinks
];
/**
     Key to be added for iOs on categoryBanners array:
     * hideForIOS : true if we don't want to show banner on iOs devices
     * showForIOS : true if we want to to show a different banenr for iOs device, create another object named categoryBannersForIOs with same format
     * * don't make both true
     * * No need to add above keys on categoryBannersForIOs, adding keys (if required) on original config array is sufficient.
     * * Don't add any key if not required
     */
export const categoryBannersForIOs = [];
export const categoryBanners = [
    //Category Banner
    {
        categoryId: "57d6897a272b564fc04b24ac",
        desktopImage: "https://media.insider.in/image/upload/c_crop,g_custom/v1730443931/fie76nud8zwxbh4om3fv.jpg",
        mobileImage: "https://media.insider.in/image/upload/c_crop,g_custom/v1730443926/wldtavuqq7uqy1dpqkuz.jpg",
        includeCities: ["mumbai"],
        link: {
            resource_type: "event",
            slug: "nh7-weekender-2024-pune-dec14-2024"
        }
    },
    {
        categoryId: "57d6897a272b564fc04b24ac",
        desktopImage: "https://media.insider.in/image/upload/c_crop,g_custom/v1728703868/e64tl9kluufwnyg3ojpg.jpg",
        mobileImage: "https://media.insider.in/image/upload/c_crop,g_custom/v1728703865/qxcgylvrdfpuyinc9t1r.jpg",
        includeCities: ["delhi"],
        link: {
            resource_type: "event",
            slug: "peter-cat-recording-co-good-luck-beta-24-delhi-dec22-2024"
        }
    },
    {
        categoryId: "57d6897a272b564fc04b24ac",
        desktopImage: "https://media.insider.in/image/upload/c_crop,g_custom/v1726904370/wfsiqatgzjrspoxpd1ob.jpg",
        mobileImage: "https://media.insider.in/image/upload/c_crop,g_custom/v1726904367/incik0abyq5fncojdd6q.jpg",
        includeCities: ["bengaluru"],
        link: {
            resource_type: "event",
            slug: "innellea-bangalore-nov17-2024"
        }
    },
    {
        categoryId: "57d6897a272b564fc04b24ac",
        desktopImage: "https://media.insider.in/image/upload/c_crop,g_custom/v1730443931/fie76nud8zwxbh4om3fv.jpg",
        mobileImage: "https://media.insider.in/image/upload/c_crop,g_custom/v1730443926/wldtavuqq7uqy1dpqkuz.jpg",
        includeCities: ["pune"],
        link: {
            resource_type: "event",
            slug: "nh7-weekender-2024-pune-dec14-2024"
        }
    },
    {
        categoryId: "57d6897a272b564fc04b24ac",
        desktopImage: "https://media.insider.in/image/upload/c_crop,g_custom/v1729664415/nzcjfaattgpytoj8c1aq.jpg",
        mobileImage: "https://media.insider.in/image/upload/c_crop,g_custom/v1729664420/ilxoma7gxgfcy5jsg7fp.jpg",
        includeCities: ["hyderabad"],
        link: {
            resource_type: "event",
            slug: "arijit-singh-india-tour-hyderabad-7dec-2024"
        }
    },
    {
        categoryId: "57d6897a272b564fc04b24ac",
        desktopImage: "https://media.insider.in/image/upload/c_crop,g_custom/v1731142347/pcfcuklk6wvty7g0ozo0.jpg",
        mobileImage: "https://media.insider.in/image/upload/c_crop,g_custom/v1731142345/x6c2xpfdu9g6l8qkzza4.jpg",
        includeCities: ["goa"],
        link: {
            resource_type: "event",
            slug: "inneallea-at-raeeth-nov15-2024"
        }
    },
    {
        categoryId: "57d6897a272b564fc04b24ac",
        desktopImage: "https://media.insider.in/image/upload/c_crop,g_custom/v1728704265/q2to3ri6hupywvy6zdra.jpg",
        mobileImage: "https://media.insider.in/image/upload/c_crop,g_custom/v1728704268/n2cxd61spyqs7ibbtwab.jpg",
        includeCities: ["chennai"],
        link: {
            resource_type: "event",
            slug: "sonu-nigam-live-in-chennai-nov30-2024"
        }
    },
    {
        categoryId: "57d6897a272b564fc04b24ac",
        desktopImage: "https://media.insider.in/image/upload/c_crop,g_custom/v1731141717/hueyzbp2dm8jc3vt8dbe.jpg",
        mobileImage: "https://media.insider.in/image/upload/c_crop,g_custom/v1731141715/ka5uoq5cioglbjhkokmf.jpg",
        includeCities: ["kolkata"],
        link: {
            resource_type: "event",
            slug: "talwiinder-live-in-kolkata-nov17-2024"
        }
    },
    {
        categoryId: "57d6897a272b564fc04b24ac",
        desktopImage: "https://media.insider.in/image/upload/c_crop,g_custom/v1728704265/q2to3ri6hupywvy6zdra.jpg",
        mobileImage: "https://media.insider.in/image/upload/c_crop,g_custom/v1728704268/n2cxd61spyqs7ibbtwab.jpg",
        includeCities: ["coimbatore"],
        link: {
            resource_type: "event",
            slug: "sonu-nigam-live-in-chennai-nov30-2024"
        }
    },
    {
        categoryId: "57d6897a272b564fc04b24ac",
        desktopImage: "https://media.insider.in/image/upload/c_crop,g_custom/v1724039161/wieerk3ojaqv5auxigy6.jpg",
        mobileImage: "https://media.insider.in/image/upload/c_crop,g_custom/v1724039166/ccvsttnb57ddne4x0bju.jpg",
        includeCities: ["visakhapatnam", "vizag"],
        link: {
            resource_type: "event",
            slug: "armaan-malik-live-in-vizag-nov9-2024"
        }
    }
];
export const showcase = [
    //Type C
    {
        mobileImage: "https://media.insider.in/image/upload/c_crop,g_custom/v1728703987/gfac98re6ce5jxypfwop.jpg",
        desktopImage: "https://media.insider.in/image/upload/c_crop,g_custom/v1728703982/mcu7jtu4cewadeo0mvsd.jpg",
        //categoryIcon: <Dhandia />,
        heading: "Comic Con 2024",
        subheading: "The greatest pop-culture extravaganza",
        caption: "An epic showcase of comics, cosplay, collectible merch, toys, immersive fan experiences, gaming, and more await. Get Your Tickets!",
        cta: "Discover more",
        includeCities: ["mumbai"],
        cardListType: "small-event-card",
        tag: "comic-con-2024",
        cardListLabel: "GRAB your tickets now!",
        link: {
            resource_type: "external",
            url: "microsite/comic-con-india-2024"
        },
        overrideShowInCurrentCityCheck: true,
        openExternalLinkInNewTab: false
    },
    {
        mobileImage: "https://media.insider.in/image/upload/c_crop,g_custom/v1730456627/y9vfld7emigw620381ew.jpg",
        desktopImage: "https://media.insider.in/image/upload/c_crop,g_custom/v1730456630/qlbhh3olb6omarjj4xqf.jpg",
        //categoryIcon: <Music />,
        heading: "Horn OK Please",
        subheading: "The 13th edition of the Happiest Food Festival!",
        caption: "Indulge in a feast for the senses with 200+ of Delhi’s favorite eateries. From comfort food to exotic flavors, there's something to satisfy every craving.",
        cta: "Buy Now",
        includeCities: ["delhi"],
        link: {
            resource_type: "event",
            slug: "horn-ok-please-the-happiest-food-festival-13-nov16-2024"
        },
        overrideShowInCurrentCityCheck: true,
        openExternalLinkInNewTab: false
    },
    {
        mobileImage: "https://media.insider.in/image/upload/c_crop,g_custom/v1730456679/f4awx6cppgod9hajbm4u.jpg",
        desktopImage: "https://media.insider.in/image/upload/c_crop,g_custom/v1730456676/ietk6g9nmfpspeqygca4.jpg",
        categoryIcon: <Music />,
        heading: "Arijit Singh - LIVE in Concert",
        subheading: "The Voice of the generation makes a much awaited comeback to the stage.",
        caption: "30th November | 5PM onwards TERRAFORM - Main Gate, Bengaluru",
        cta: "Buy Now",
        includeCities: ["bengaluru"],
        link: {
            resource_type: "event",
            slug: "arijit-singh-india-tour-bengaluru-30nov-2024"
        },
        overrideShowInCurrentCityCheck: true,
        openExternalLinkInNewTab: false
    },
    {
        mobileImage: "https://media.insider.in/image/upload/c_crop,g_custom/v1728703987/gfac98re6ce5jxypfwop.jpg",
        desktopImage: "https://media.insider.in/image/upload/c_crop,g_custom/v1728703982/mcu7jtu4cewadeo0mvsd.jpg",
        //categoryIcon: <Dhandia />,
        heading: "Comic Con 2024",
        subheading: "The greatest pop-culture extravaganza",
        caption: "An epic showcase of comics, cosplay, collectible merch, toys, immersive fan experiences, gaming, and more await. Get Your Tickets!",
        cta: "Discover more",
        includeCities: ["hyderabad"],
        cardListType: "small-event-card",
        tag: "comic-con-2024",
        cardListLabel: "GRAB your tickets now!",
        link: {
            resource_type: "external",
            url: "microsite/comic-con-india-2024"
        },
        overrideShowInCurrentCityCheck: true,
        openExternalLinkInNewTab: false
    },
    {
        mobileImage: "https://media.insider.in/image/upload/c_crop,g_custom/v1723437156/tegvlohjwrzfuftqo44o.jpg",
        desktopImage: "https://media.insider.in/image/upload/c_crop,g_custom/v1723437160/yhdr4vaqmlnjjemq9ij1.jpg",
        categoryIcon: <Music />,
        heading: "PCRC LIVE!",
        subheading: "Enter the musical ‘Portrait Of A Time’ with Peter Cat Recording Co.",
        caption: "The band is coming to your city with their ‘Good Luck Beta’ tour! Brace yourselves for a mind-blowing performance and fresh new tracks!",
        cta: "Book Now",
        includeCities: ["goa"],
        cardListType: "schedule-card",
        tag: "good-luck-beta-tour",
        cardListLabel: "GRAB your tickets now!",
        link: {
            resource_type: "external",
            url: "microsite/pcrc-goodluckbeta24"
        },
        overrideShowInCurrentCityCheck: true,
        openExternalLinkInNewTab: false
    },
    {
        mobileImage: "https://media.insider.in/image/upload/c_crop,g_custom/v1723437156/tegvlohjwrzfuftqo44o.jpg",
        desktopImage: "https://media.insider.in/image/upload/c_crop,g_custom/v1723437160/yhdr4vaqmlnjjemq9ij1.jpg",
        categoryIcon: <Music />,
        heading: "PCRC LIVE!",
        subheading: "Enter the musical ‘Portrait Of A Time’ with Peter Cat Recording Co.",
        caption: "The band is coming to your city with their ‘Good Luck Beta’ tour! Brace yourselves for a mind-blowing performance and fresh new tracks!",
        cta: "Book Now",
        includeCities: ["pune"],
        cardListType: "schedule-card",
        tag: "good-luck-beta-tour",
        cardListLabel: "GRAB your tickets now!",
        link: {
            resource_type: "external",
            url: "microsite/pcrc-goodluckbeta24"
        },
        overrideShowInCurrentCityCheck: true,
        openExternalLinkInNewTab: false
    },
    //type A
    {
        mobileImage: "https://media.insider.in/image/upload/c_crop,g_custom/v1728704425/eybpd6wbpfzwbtzvvxno.jpg",
        desktopImage: "https://media.insider.in/image/upload/c_crop,g_custom/v1728704421/ffyd2mirdeonzyvwkzl6.jpg",
        categoryIcon: <Music />,
        heading: "First time in Kolkata",
        subheading: "Sunidhi's I Am Home Concert",
        caption: "Known for her vocal range & versatility, Sunidhi Chauhan has recorded songs for films in several Indian languages and is the recipient of many awards for the same.",
        cta: "Buy Now",
        includeCities: ["kolkata"],
        link: {
            resource_type: "event",
            slug: "sunidhis-i-am-home-i-kolkata-dec24-2024"
        },
        overrideShowInCurrentCityCheck: true,
        openExternalLinkInNewTab: false
    },
    {
        mobileImage: "https://media.insider.in/image/upload/c_crop,g_custom/v1728703987/gfac98re6ce5jxypfwop.jpg",
        desktopImage: "https://media.insider.in/image/upload/c_crop,g_custom/v1728703982/mcu7jtu4cewadeo0mvsd.jpg",
        //categoryIcon: <Dhandia />,
        heading: "Comic Con 2024",
        subheading: "The greatest pop-culture extravaganza",
        caption: "An epic showcase of comics, cosplay, collectible merch, toys, immersive fan experiences, gaming, and more await. Get Your Tickets!",
        cta: "Discover more",
        includeCities: ["chennai"],
        cardListType: "small-event-card",
        tag: "comic-con-2024",
        cardListLabel: "GRAB your tickets now!",
        link: {
            resource_type: "external",
            url: "microsite/comic-con-india-2024"
        },
        overrideShowInCurrentCityCheck: true,
        openExternalLinkInNewTab: false
    },
    {
        mobileImage: "https://media.insider.in/image/upload/c_crop,g_custom/v1729499916/swuqltvcmlc2ozcsihux.jpg",
        desktopImage: "https://media.insider.in/image/upload/c_crop,g_custom/v1729499911/zkor48c826xgclsdk05v.jpg",
        categoryIcon: <Music />,
        heading: "Sonu Nigam LIVE",
        subheading: "In Chennai on November 30th.",
        caption: "A renowned playback singer who is celebrated for his versatile voice and emotive performances across various genres including Bollywood, pop, and classical music.",
        cta: "Buy Now",
        includeCities: ["coimbatore"],
        link: {
            resource_type: "event",
            slug: "sonu-nigam-live-in-chennai-nov30-2024"
        },
        overrideShowInCurrentCityCheck: true,
        openExternalLinkInNewTab: false
    },
    {
        mobileImage: "https://media.insider.in/image/upload/c_crop,g_custom/v1724225442/hy0evrvrfjqtpjcst8na.jpg",
        desktopImage: "https://media.insider.in/image/upload/c_crop,g_custom/v1724225439/fijvxhp4z4s7gsrtaeo2.jpg",
        categoryIcon: <Music />,
        heading: "First time in Vizag!",
        subheading: "Catch Armaan Malik LIVE this November!",
        caption: "Known for his soulful voice and chart-topping hits - from romantic ballads to foot-tapping numbers, experience the magic of his music up close and personal.",
        cta: "Buy Now",
        includeCities: ["visakhapatnam", "vizag"],
        link: {
            resource_type: "event",
            slug: "armaan-malik-live-in-vizag-nov9-2024"
        },
        overrideShowInCurrentCityCheck: true,
        openExternalLinkInNewTab: false
    }
];

import { colors } from "../../../styles/common";
export const styles = (showViewAll, micrositePrimaryColor) => `
	display: inline-block;
	overflow: hidden;
    text-overflow: ellipsis;
	text-transform: uppercase;
	max-height: 32px;
	white-space: nowrap;

	color: ${micrositePrimaryColor ? micrositePrimaryColor : colors.descriptionColor};
	font-family: 'korolev-condensed';
	font-size: 28px;


	font-weight: 700;
	line-height: 100%;
	text-transform: uppercase;

	@media screen and (max-width: 425px) {
		color: ${micrositePrimaryColor ? micrositePrimaryColor : colors.descriptionColor};
		font-size: 25px;
		width: ${showViewAll ? "48vw" : "70vw"};
	}
`;

import { connect } from "react-redux";
// components
import HomeView from "../components/HomeView";
// actions + selectors
import { clearSiteDisplayMode, fetchGooutHomeResources, updateSelectedCity } from "../actions/index";
import { getDisplayMode, getHomepageDataReceived, getHomepageDataRequested } from "../selectors/homepage";
import { getSelectedCityName, getUnpinnedCities, getUserProfile, getLoginDataReceived } from "../selectors/index";
const mapStateToProps = (state) => ({
    homepageDataRequested: getHomepageDataRequested(state),
    homepageDataReceived: getHomepageDataReceived(state),
    selectedCity: getSelectedCityName(state),
    unpinnedCities: getUnpinnedCities(state),
    displayMode: getDisplayMode(state),
    userInfo: getUserProfile(state),
    loginDataReceived: getLoginDataReceived(state)
});
const mapDispatchToProps = (dispatch) => ({
    getHomepageData: (city, banner_id, device) => dispatch(fetchGooutHomeResources(city, banner_id, device)),
    updateSelectedCity: (city) => dispatch(updateSelectedCity(city)),
    clearSiteDisplayMode: () => dispatch(clearSiteDisplayMode())
});
const HomePageWithSubscription = connect(mapStateToProps, mapDispatchToProps)(HomeView);
// set static on this component
// for its preload chunk
HomePageWithSubscription.preloadChunkName = "HomePage";
HomePageWithSubscription.cacheString = "public, max-age=300";
export default HomePageWithSubscription;
